import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { navigateToNewPage } from "../../actions/navigation";
import createInvoice from "../../components/PdfReceipt/PdfReceipt";
import { changeLocaleActiveItem } from "../../actions/locale";
import { getOrder, getMultivendorOrder } from "../../api/order.js";
import ProgressIndicator from "../../components/ProgressIndicator";
import queryString from 'query-string';
const { v4: uuidv4 } = require('uuid');

// need a sleep function for hyperpay payment gateway
// as calling the orderstatus endpoint is ending up with 502 response
// due to a request too fast to the server while order data is being updated

class Orderstatus extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);

    this._isMounted = false;
    this._isLocaleUpdated = false;

    this.state = {
      pdfInvoiceUrl: "",
      paymentError: ""
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { dispatch, location } = this.props;
    const { pathname } = location
    const activeLocaleItem = JSON.parse(
      localStorage.getItem(`activeLocaleItem-${pathname.includes('dineout') ? 'dineout' : 'dinein'}`)
    );
    const nonActiveLocales = JSON.parse(
      localStorage.getItem(`nonActiveLocales-${pathname.includes('dineout') ? 'dineout' : 'dinein'}`)
    );
    // dispatch language change to get the right language loaded for the receipt
    if (activeLocaleItem && nonActiveLocales)
      dispatch(changeLocaleActiveItem(activeLocaleItem, nonActiveLocales));
    // console.log("here we are");
    if (this.props.websocket) {
      // get the new connection id to place a new order
      const { websocket } = this.props
      websocket.send(JSON.stringify({'message': uuidv4()}));
    }
  }

  async componentWillReceiveProps(nextProps) {
    const deliveryMethod = this.props.match.params.deliverymethod;
    const queryStringParamMode = queryString.parse(this.props.location.search).mode
    if (!this._isLocaleUpdated && this._isMounted) {
      this._isLocaleUpdated = true;
      const { params } = this.props.match;
      const orderId = params.orderid;
      try {
        // if the url contains the word restcollection then otherwise we are retrieving a single restaurant order
        const urlHash = window.location.hash;
        const indexOfRestaurantCollection = urlHash.indexOf("/restcollection/");
        const isMultivendorOrder = indexOfRestaurantCollection !== -1;
        let orders;
        // adding 2.5 seconds sleep time for orders loading so that it gives time for the backend to update the status of the orders from the webhook call from mollie
        await new Promise(resolve => setTimeout(resolve, 2500));
        if (isMultivendorOrder) {
          // add a sleep function of 2 seconds for multivendor orders
          orders = await getMultivendorOrder(orderId);
        } else {
          orders = await getOrder(orderId);
        }
        // loop through the orders to process them
        const invoicesToPrint = [];
        for(let index = 0; index < orders.length; index++) {
          const currentOrder = orders[index];
          const { paymentInformation, restaurant, paymentError, isInvoiceGenerationEnabled } = currentOrder;
          console.log("currentOrder to print receitp for => ", currentOrder);
          // we only display the receipt if the order is paid otherwise the user is redirected to the menu
          if ((paymentInformation && paymentInformation.statusCode && paymentInformation.statusCode === 'paid') || isInvoiceGenerationEnabled) {
            const { createdOn, orderContent } = currentOrder;
            const createdOnDate = new Date(createdOn);
            const invoice = {
              restaurant: {
                companyName: restaurant.companyName,
                address1: restaurant.streetNameAndNumber,
                address2: `${restaurant.zipPostalCode} ${restaurant.city.name}, ${restaurant.city.country.name}`,
                phoneNumber: restaurant.grp_phonenumbers[0].number,
                companyCoCNumber: restaurant.companyRegistrationNumber,
                logoUrl: restaurant.logo && restaurant.logo.formats && restaurant.logo.formats.small ? restaurant.logo.formats.small.url : restaurant.logo.url,
              },
              /*shipping: {
                name: "John Doe",
                address: "1234 Main Street",
                city: "San Francisco",
                state: "CA",
                country: "US",
                postal_code: 94111,
              },*/
              orderDatetime: createdOnDate,
              totalPriceBeforeDiscount: currentOrder.totalPriceBeforeDiscount * 100,
              subtotal: currentOrder.totalPrice * 100,
              paid: currentOrder.totalPrice * 100,
              invoice_nr: currentOrder.orderNumber,
              orderPaymentStatus: paymentInformation ? paymentInformation.statusCode : "notpaid",
              currencySymbol: restaurant.city.country.currency.symbol,
            };
            const { locale } = this.props.intl;
            let orderedItemDescription;
            const orderedItems = orderContent.map((orderedItem) => {
              // console.log('orderedItem => ', orderedItem);
              let paidAddonsText = "";
              let paidAddonsTotalPrice = -1;
              if (orderedItem.addonsgroups) {
                for (
                  let index = 0;
                  index < orderedItem.addonsgroups.length;
                  index++
                ) {
                  const addongroup = orderedItem.addonsgroups[index];
                  for (
                    let index2 = 0;
                    index2 < addongroup.selectedAddons.length;
                    index2++
                  ) {
                    const selectedAddon = addongroup.selectedAddons[index2];
                    if (selectedAddon.price > 0) {
                      // set the total price of addons to 0 if there
                      // are addons with a price > 0 so that we do not add things starting
                      // with a value from -1
                      if (paidAddonsTotalPrice === -1) {
                        paidAddonsTotalPrice = 0
                      }
                      /* paidAddonsText +=
                        "[" + selectedAddon.name_i18n[locale] + "]"; */
                      paidAddonsTotalPrice += selectedAddon.price;
                    } else {
                      if (selectedAddon.price === 0) {
                        paidAddonsTotalPrice += 0
                      }
                    }
                  }
                }
                orderedItemDescription = {
                  description: `${
                    orderedItem.name_i18n[locale].length > 18
                      ? orderedItem.name_i18n[locale].slice(0, 18) + "."
                      : orderedItem.name_i18n[locale]
                  } (${orderedItem.size.name_i18n[locale]}) ${paidAddonsTotalPrice > -1 ? "[+extras]" : ""}${paidAddonsText}`,
                  quantity: orderedItem.size.quantity,
                  amount: (orderedItem.size.price + (paidAddonsTotalPrice > -1 ? paidAddonsTotalPrice : 0)) * 100,
                };
              } else {
                // if we are in combo menu item mode
                if (orderedItem.menugroupid) {
                  orderedItemDescription = {
                    description: `${
                      orderedItem.name_i18n[locale].length > 18
                        ? orderedItem.name_i18n[locale].slice(0, 18) + "."
                        : orderedItem.name_i18n[locale]
                    }`,
                    quantity: orderedItem.quantity,
                    amount: orderedItem.price * 100,
                  };
                }
              }
  
              return orderedItemDescription;
            });
            invoice.items = orderedItems;
            invoicesToPrint.push(invoice);
          } else {
            if (!paymentError) {
              // Retrieve the first available restaurantCollection-slug from localStorage
              // not ideal but it works for now
              let restaurantCollectionSlug = null;
              for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (key.startsWith('restaurantCollection-slug-')) {
                  restaurantCollectionSlug = localStorage.getItem(key);
                  break;
                }
              }
              if (paymentInformation.statusCode && paymentInformation.statusCode !== 'paid') {
                console.log("Order not paid => ", currentOrder);
              }
              //const restaurantCollectionSlug = localStorage.getItem('restaurantCollection-slug');
              if(restaurantCollectionSlug && restaurant) {
                this.props.history.push(
                  `/app/${deliveryMethod}/restaurant/${restaurant.slug}/menus?rcid=${restaurantCollectionSlug}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}`
                );
              } else {
                if(restaurant) {
                  this.props.history.push(
                    `/app/${deliveryMethod}/restaurant/${restaurant.slug}/menus${queryStringParamMode ? '?mode=' + queryStringParamMode : ''}`
                  );
                }
              }
            } else {
              this.setState({
                paymentError: paymentError.description
              });
              console.log("payment error", JSON.stringify(paymentError));
            }
          }
        }
        createInvoice(
          invoicesToPrint,
          this.setPDFInvoiceURL.bind(this),
          this.props.intl
        );
      } catch (error) {
        console.log("error when retrieving order information", error);
      }
      this.dispatchPageNavigationToHeader();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  dispatchPageNavigationToHeader(strTitleToDisplay = "") {
    const { dispatch } = this.props;
    const restaurantid = this.props.match.params.restaurantid;
    const deliveryMethod = this.props.match.params.deliverymethod;
    const { intl } = this.props;
    let urlToGoTo = ''
    // not ideal but it works for now
    let restaurantCollectionSlug = null;
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith('restaurantCollection-slug-')) {
        restaurantCollectionSlug = localStorage.getItem(key);
        break;
      }
    }
    const urlParams = new URLSearchParams(window.location.hash.split('?')[1] || '');
    const rcid = urlParams.get('rcid');
    // If we're in restaurant collection mode we need to reset the right cart
    if (rcid) {
      restaurantCollectionSlug = rcid;
    }
    //console.log('restaurantCollectionSlug => ', restaurantCollectionSlug);
    //const restaurantCollectionSlug = localStorage.getItem('restaurantCollection-slug');
    const queryStringParamMode = queryString.parse(this.props.location.search).mode
    // console.log('dispatchPageNavigationToHeader => ', restaurantCollectionSlug);
    if(restaurantCollectionSlug) {
      urlToGoTo = `/app/${deliveryMethod}/restaurant/${restaurantid}/menus?rcid=${restaurantCollectionSlug}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}`
    } else {
      urlToGoTo = `/app/${deliveryMethod}/restaurant/${restaurantid}/menus${queryStringParamMode ? '?mode=' + queryStringParamMode : ''}`
    }
    dispatch(
      navigateToNewPage(
        intl.formatMessage({
          id: "Orderstatus.page.header.title",
          defaultMessage: "Receipt",
        }),
        urlToGoTo
      )
    );
  }

  setPDFInvoiceURL(url) {
    if (this._isMounted) {
      this.setState({
        pdfInvoiceUrl: url
      });
    }
  }

  render() {
    return (this.state.paymentError !== "" ? (
        <div>
          Payment failed with following error:<br /><br />
        <span style={{fontSize: "18px", textAlign: "center", paddingTop: "50px", fontWeight: "700"}}>
          {this.state.paymentError}
        </span>
        <br /><br />
        <span>
          Please try again and if the issue keep persisting then contact the outlet.
        </span>
        </div>
      ) : this.state.pdfInvoiceUrl === "" ? (
        <ProgressIndicator />
      ) : (
      <iframe
        title="Invoice"
        src={`/web/viewer.html?file=${this.state.pdfInvoiceUrl}`}
        style={{
          width: "100%",
          height: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
          position: "absolute",
          paddingRight: "5%",
          paddingBottom: "150px",
        }}
        marginWidth="0"
        marginHeight="0"
        frameBorder="0"
        vspace="0"
        hspace="0"
        seamless="seamless"
      />
    ));
  }
}

function mapStateToProps(store) {
  return {
    restaurantInfo: store.restaurant.restaurantInfo,
    websocket: store.websocket.socket
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(Orderstatus)));
